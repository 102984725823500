import { FC, useState } from 'react'
import { useI18nContext } from '@/i18n/i18n-react'
import Feature from './Feature'

type FeaturesProps = {
    createdBy?: boolean
}

const Features: FC<FeaturesProps> = ({ createdBy = false }) => { 
    
    const { LL } = useI18nContext()

    const featureSlides = [
        {
            title: LL.landing.createdBy.features.designExplorer.title(),
            text: LL.landing.createdBy.features.designExplorer.text(),
            image: '/images/landing/features-01.png'
        },
        {
            title: LL.landing.createdBy.features.dashboard.title(),
            text: LL.landing.createdBy.features.dashboard.text(),
            image: '/images/landing/features-02.png'
        },
        {
            title: LL.landing.createdBy.features.help.title(),
            text: `${LL.landing.createdBy.features.help.text()}<br />${LL.landing.createdBy.features.help.text2()}`,
            image: '/images/landing/features-03.png'
        },
        {
            title: LL.landing.createdBy.features.stock.title(),
            text: LL.landing.createdBy.features.stock.text(),
            image: '/images/landing/features-04.png'
        }
    ]

    const [featureActive, setFeatureActive] = useState(0)
    const [featureImageURL, setFeatureImageURL] = useState(featureSlides[0].image)

    const isMobile = () => window.innerWidth <= 576
    
    return (
        <section id="features" className="features hideHeader">
            <div className="features--wrapper inview--fade-in">
                <div className="left">
                    {
                        createdBy 
                        ? <>
                            <div className="title">{LL.landing.createdBy.features.build.title()}</div>
                            <div className="text">{LL.landing.createdBy.features.build.text()}<br />{LL.landing.createdBy.features.build.text2()}<strong>{LL.landing.createdBy.features.build.text3()}</strong>{LL.landing.createdBy.features.build.text4()}<br /><strong>{LL.landing.createdBy.features.build.text5()}</strong></div>
                            
                            <div className="features-image image">
                                <img src={featureImageURL} alt={LL.landing.createdBy.features.title()} width={650} height={416} />
                            </div>
                        </>
                        : <>
                            <div className="title">{LL.landing.createdBy.features.title()}</div>
                            <div className="text">{LL.landing.createdBy.features.build.text()}<br />{LL.landing.createdBy.features.build.text2()}<strong>{LL.landing.createdBy.features.build.text3()}</strong>{LL.landing.createdBy.features.build.text4()}<br /><strong>{LL.landing.createdBy.features.build.text5()}</strong></div>
                            <div className="spacer" />
                            <div className="cta">
                                <a href="/signup" className="btn--cta" id="btTryOxion">{LL.landing.createdBy.tryNow()}</a>
                            </div>

                            <div className="features-image image">
                                <img src={featureImageURL} alt={LL.landing.createdBy.features.title()} width={650} height={416} />
                            </div>
                        </>
                    }
                    
                </div>
                <div className="right">

                    {
                        !createdBy && <div className="tabs">
                            <div className="tab active" data-target="item-01">{LL.landing.createdBy.features.available()}</div>
                            {/* <div className="tab" data-target="item-02">UPCOMING</div> */}
                        </div>
                    }
                    
                    <div className="collapse">
                        {
                            featureSlides.map((feature,index) => <Feature key={`f-${feature.image}`} id={`${index}`} title={feature.title} text={feature.text} image={feature.image} active={index === featureActive} handleClick={() => { 
                                setFeatureImageURL(feature.image) 
                                if(!isMobile()) {
                                    setFeatureActive(index)
                                }
                            }} />)
                        }
                    </div>

                    <div className="features-image features-image-mobile image">
                        <img src={featureImageURL} alt={LL.landing.createdBy.features.title()} width={650} height={416} />
                    </div>

                </div>
            </div>
        </section>
    )
}


export default Features