import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { IconButton } from '@/components/common/IconButton'
import { IconDash, IconPlus } from '@/components/common/IconButton/consts'
import clsx from 'clsx'

type FeatureProps = {
    id: string,
    title: string,
    text: string,
    image: string,
    handleClick: (event: SyntheticEvent<Element, Event>) => void,
    active?: boolean
}

const Feature: FC<FeatureProps> = ({ id, title, text, image, handleClick, active = false }) => {   
    
    const [featureActive, setFeatureActive] = useState<boolean>(active)

    useEffect(() => {
        setFeatureActive(active)
    }, [active])
    
    return (
        <button aria-label={title} className={clsx(`item item-0${id}`, featureActive && 'active')} data-img={image} onClick={(e) => { setFeatureActive(prevState => !prevState); handleClick(e) }}>
                <IconButton handleClick={() => { }}>
                    {
                        featureActive ? <IconDash /> : <IconPlus />
                    }
                </IconButton>
                <h3 className="title" dangerouslySetInnerHTML={{__html: title}} />
                <div className="infoWrapper">
                    <h4 className="text" dangerouslySetInnerHTML={{__html: text}} />
                    <img src={image} alt={title} />
                </div>
        </button>
    )
}


export default Feature