import { blockScroll, unBlockScroll } from '@/lib/utils'
import Player from '@vimeo/player'

export const videoButtons = () => {
    const videoButton = document.querySelector('.btn--video') as HTMLElement;
    const closeButton = document.querySelector('.close-button') as HTMLElement;
    const popup = document.querySelector('.video-popup') as HTMLElement;
    const videoLayer = document.querySelector('.video-layer') as HTMLElement;

    const iframe = document.querySelector('.video-popup iframe') as HTMLElement;
    const player = new Player(iframe ?? '');

    videoButton.addEventListener('click', (e) => {
        player.play()
        e.preventDefault()
        popup.classList.add('active')
        blockScroll()
    });

    closeButton.addEventListener('click', (e) => {
        player.pause()
        e.preventDefault()
        popup.classList.remove('active');
        unBlockScroll();
    });

    popup.addEventListener('click', (e) => {
        e.preventDefault();
        // @ts-expect-error enable e
        if (!videoLayer.contains(e.target)) {
            player.pause();
            popup.classList.remove('active');
            unBlockScroll();
        }
    });
}

export const setLinks = () => {
    const links = document.querySelectorAll<HTMLElement>('.landing-oxion a[href^="#"]');

    links.forEach(link => {
        link.addEventListener('click', (e) => {
            const hRef = (e.currentTarget as HTMLElement)?.getAttribute('href') ?? '';
            
            if ( hRef === '#' ) return;
            e.preventDefault();

            const target = document.querySelector(hRef);
            if (target) {
                
                if ( hRef.toString() === '#benefits'  ) {
                    target.scrollIntoView({behavior: 'smooth'});
                    const customEvent = new Event('scrollWhy');
                    document.dispatchEvent(customEvent);
                }
                else {
                    target.scrollIntoView({behavior: 'smooth'});
                }
            }
        });
    });
}

export const inview = () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-require-imports
    const inView = require('in-view');

    inView('.gallery.fade-in .images .img')
        // @ts-expect-error let el to be any
        .on('enter', el => {
            el.classList.add('active')
        })
    ;

    inView('.inview--fade-in')
        // @ts-expect-error let el to be any
        .on('enter', el => {
            el.classList.add('active')
        })

    inView('.inview--active')
        // @ts-expect-error let el to be any
        .on('enter', el => {
            el.classList.add('active')
        })
}