import { FC } from 'react'

const VideoPopup: FC = () => {  

    return (
        <div className="video-popup">
            <div className="video-layer">
                <a aria-label="Close" href="#" className="close-button"><img src="/images/landing/plus-white.svg" alt="Oxion" width={28} height={28} /></a>
                <div className="iframe-container">
                    <iframe width="560" height="315" src="https://player.vimeo.com/video/929885936?h=14d5142081&controls=0" title="Oxion" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                </div>
            </div>
        </div>
    )
}


export default VideoPopup