import { FC, useEffect, useState } from 'react'

const StatusBar: FC = () => {    

    const [percentageScrolled, setPercentageScrolled] = useState(0)

    useEffect(() => {
        const getPercentagePageScrolled = () => {
            const { body, documentElement: html }  = document
            
            const totalHeight = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);
            const scrolledFromTop = window.scrollY;
            const heightToScroll = totalHeight - window.innerHeight;
    
            const scrollPercentage = (scrolledFromTop / heightToScroll) * 100;
            setPercentageScrolled(scrollPercentage)
        }

        window.addEventListener('scroll', getPercentagePageScrolled);

        return () => {
            window.removeEventListener('scroll', getPercentagePageScrolled);
        };
    }, []);
    
    return (
        <div className="status-bar">
            <div className="bar" style={{width: `${percentageScrolled}%`}} />
        </div>
    )
}


export default StatusBar